.removeMemberModalContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;
    width: 300px;
    height: 94%;
}

.removeModalInfo {
    height: 110px;
}
.removeModalButtonsContainer {
    display: flex;
    justify-content: space-around;
    margin-top:auto;
}
