.start-view-container {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  max-width: 380px;
}

.leftSectionWeb {
  display: none;
}
.hideOnMobile {
  display: none;
}

.logoOnMobile {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
}

.successPageTickets {
  margin: 10px 13px;
}

.successPageTitle {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  color: #2b2b2c;
  padding-top: 20px;
}

.successPageHeader {
  width: 994px;
  height: 180px;
  background-color: #fff;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  display: none;
}

.successPageTitleBig {
  font-size: 50px;
  color: #b30019;
  font-weight: 800;
  margin-left: 25px;
  line-height: 1;
}
.tileWithSelect {
  background-color: #fff;
  height: 220px;
}
.rightSectionWeb {
  display: none;
}
@media (min-width: 900px) {
  .start-view-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0;
    max-width: 1380px;
  }
  .leftSectionWeb {
    display: flex;
    flex-direction: column;
    max-width: 30%;
  }
  .leftSectionMobile {
    display: none;
  }
  .tileContainer {
    padding-top: 22px;
    padding-left: 16px;
    width: 100%;
  }
  .rightSectionWeb {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1145px) and (max-width: 1250px) {
    .rightSectionWeb {
      padding-left: 5vw;
    }
  }

  @media (min-width: 1000px) and (max-width: 1144px) {
    .rightSectionWeb {
      padding-left: 5vw;
    }
  }

  @media (min-width: 1000px) and (max-width: 1144px) {
    .rightSectionWeb {
      padding-left: 6vw;
    }
  }

  @media (min-width: 900px) and (max-width: 999px) {
    .rightSectionWeb {
      padding-left: 9vw;
    }
  }

  .rightTop {
    width: 97%;
    margin: 0 auto;
    padding-bottom:10px;
    align-self: center;
  }
  .rightBottom {
    width: 1008px;
    display: flex;
  }
  .hideOnMobile {
    display: initial;
    width: 31%;
  }
  .successPageHeader {
    display: flex;
  }
}

