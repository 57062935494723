.flims-laax-logo {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

span.companyName {
  font-size: 11px;
  color: #000;
  display: block;
}

.flims-laax-logo-link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 10;
}
