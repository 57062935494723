.subheader {
    display: flex;
    align-items: center;
}

.subheader-icon {
    width: 2em;
    height: 2.2em;
    padding: 0 0.5em 0.5em 0;
}
