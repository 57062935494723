.small-main-view-tile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.small-main-view-tile-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
}

@media (min-width: 900px) {
  .small-main-view-tile-container {
    width: 100%;
  }
}
.small-main-view-tile-img {
  height: 164px;
  background-size: cover;
}
.dots-menu-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.small-main-view-title {
  height:70px;
  display: flex;
  align-items: center;
  padding-left:10px;
  cursor: pointer;
}

.small-main-view-description {
  color: #787878;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  line-height: 1.5;
}

.small-main-view-footer {
  width: 100%;
  color: #787878;
}
.small-main-view-footer > span {
  display: inline-block;
  width: 100%;
  text-align: center;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  margin-top: 20px;
  font-size: 14px;
}
