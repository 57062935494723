.addMemberContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px;
  width: 300px;
}

.keyCardTitle {
  font-size: 16px;
  font-weight: 900;
  margin-top: -2px;
}

.selectInAddMember {
  margin: 0;
  padding: 0;
}

.exitButton {
  position: absolute;
  right: 0;
  top: 0;
}
