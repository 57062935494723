.bookTicketStatusBarMobileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
}

.statusBarContainerMobile {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 0;
  padding-left: 16px;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 9px;
  width: 25px;
  text-transform: uppercase;
  margin: 0 19px;
}
.iconDescription {
  margin-top: 5px;
  color: #b7b7b7;
  font-size: 12px;
}

.activeRedColorText {
  color: #af272f;
  font-weight: bold;
}
.activeRedColorLine {
  background-color: #af272f;
}

/* Booking ticket steps */

.bookTicketStepsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.bookTicketStep {
  background-color: #fff;
  border: none;
  color: #787878;
  font-size: 14px;
}
.arrowRightGrey {
  width: 10px;
  margin: 0 10px;
}

.stepActive {
  font-weight: bold;
}

.subtotalMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 124px !important;
  height: 50px;
  background-color: #b30019;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  padding-top: 12px;
  font-size: 10px;
}
.subtotalPrice {
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  font-size: 16px;
}

a {
  color: #b30019;
  text-decoration: none;
}

.fixedWrapper {
  position: fixed;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
}
