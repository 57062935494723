.confirmKeyCardContainer {
  width: auto;
  position: relative;
}


@media (min-width: 900px) {
  .confirmKeyCardContainer {
     width: 500px;
  }
}
