.checkoutTotalContainer {
  width: 285px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 25px;
  position: relative;
  padding: 10px 15px 25px;
}

h3 {
  font-size: 18px;
  text-transform: uppercase;
  color: #2b2b2c;
  display: flex;
}

.dollarIcon {
  fill: #b30019;
  height: 20px;
  font-weight: bold;
  align-self: center;
}
.costsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.currency {
  color: #b30019;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-right: 10px;
}
.price {
  color: #b30019;
  font-size: 52px;
  font-weight: bold;
  line-height: 1;
}
.discountCurrency{
  font-size: 14px;
  padding-right:6px;
  padding-top:4px;
}
.discountPrice{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 50px;
  right: 50px;
  color: #b30019;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}
.discountLine{
  position: absolute;
  background-color: #b30019;
  height: 1px;
  width: 100%;
}
span.vat {
  display: block;
  width: 100%;
  color: #787878;
  text-align: center;
  margin-top: 8px;
}

.grayedTotalBox {
  opacity: 0.4;
  text-align: center;
  pointer-events: none;
}

.totalBox {
text-align: center;
}
.payButtonContainer {
 display: none;
}
.loginButtonContainer {
  position: absolute;
  bottom: -75px;
  right: -16px;
}
.addToken {
  width:100%;
  display: flex;
  align-self: flex-start;
  font-size: 16px;
  font-family: Helvetica Neue LT W05_55 Roman,sans-serif;
  opacity: 0.5;
  text-decoration: underline;
  cursor: pointer;
  margin: 10px 0 20px;
}
@media (min-width: 900px) {
  .totalBox {
    text-align: center;
    position:static;
  }
  .payButtonContainer {
    display: initial;
  }
  .loginButtonContainer {
    position: initial;
  }
}
