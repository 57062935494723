.toggleManuWithListedOptionsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.cancel-img {
  display: flex;
}

@media (min-width: 900px) {
  .cancel-img {
  display: none;
  }
}
