.downloadAppContainer {
  width: 312px;
  height: 466px;
  position: relative;
  background-color: #b30019;
}
.downloadAppTitle {
  color: #fff;
  font-size: 33px;
  box-sizing: border-box;
  padding: 25px;
}
@media (min-width: 900px) {
  .downloadAppPhoneIMG {
    position: absolute;
    bottom: 0;
    right: -15px;
    width: 200px;
  }
}
@media (min-width: 1px) and (max-width: 1379px) {
  .downloadAppPhoneIMG {
    position: absolute;
    bottom: 0;
    right: -15px;
    width: 180px;
  }
}
.downloadAppInsideIMG {
  position: absolute;
  width: 80px;
  bottom: 100px;
  left: 25px;
}

.downloadAppAppleIMG,
.downloadAppAndroidIMG {
  position: absolute;
  bottom: 30px;
  left: 15px;
  width: 40px;
}
.downloadAppAndroidIMG {
  left: 68px;
}
.downloadAppButton {
  position: absolute;
  top: 190px;
  left: 25px;
  border: none;
  color: #b30019;
  background-color: #fff;
  height: 40px;
  width: 135px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  box-sizing: border-box;
  padding-top: 5px;
}

.downloadAppButton > a {
  width: 100%;
  height: 100%;
  line-height: 2.5;
  display: block;
  color: #b30019;
}

@media (min-width: 900px) {
  .downloadAppContainer {
    width: 322px;
  }
}
