@import url(https://fast.fonts.net/cssapi/89b15ae3-041a-4056-a712-2f1fe1dd5431.css);
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* line-height: 1.86; */
  font-family: Helvetica Neue LT W05_55 Roman,sans-serif;
  letter-spacing: 0.03em;
  font-size: 18px;
  background-color: transparent;
}
span {
  font-size: 14px;
}
.wrapper-main-container {
  background-color: #f0f0f0;
}
.main-container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

@media (min-width: 1380px) {
  .main-container {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

@media (min-width: 1251px) and (max-width: 1379px) {
  .main-container {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

@media (min-width: 1145px) and (max-width: 1250px) {
  .main-container {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

@media (min-width: 1000px) and (max-width: 1144px) {
  .main-container {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

@media (min-width: 900px) and (max-width: 999px) {
  .main-container {
    -webkit-transform: scale(0.65);
            transform: scale(0.65);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

code {
  font-family: Roboto, sans-serif;
}

h1 {
  color: #b30019;
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: 1.5;
}
h1,h2,
h3 {
  /*font-family: Helvetica Now Text, ExtraBold, sans-serif;*/
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  letter-spacing: .005em;
}

.font-grey {
  color: #787878;
}

.element-on-hover {
  cursor: pointer;
}

.small-grey-text {
  font-size: 0.7em;
  line-height: 90%;
}

.p-main {
  color: #787878;
}

.react-datepicker__day--selected {
  background-color: #b30019 !important;
}
.react-datepicker__navigation--next {
  background-color: #ccc !important;
  -webkit-mask: url(/static/media/arrow-right-black.d2cff4d9.svg) no-repeat center !important;
          mask: url(/static/media/arrow-right-black.d2cff4d9.svg) no-repeat center !important;
  margin: 5px !important;
  width: 15px !important;
  height: 15px !important;
  border: none !important;
}
.react-datepicker__navigation--previous {
  background-color: #ccc !important;
  -webkit-mask: url(/static/media/arrow-left-red.d2dddb1a.svg) no-repeat center !important;
          mask: url(/static/media/arrow-left-red.d2dddb1a.svg) no-repeat center !important;
  margin: 5px !important;
  width: 15px !important;
  height: 15px !important;
  border: none !important;
}
.focusRing___1airF.carousel__slide-focus-ring { outline: none !important; }
.transparent-btn {
  background: transparent;
  border: none !important;
}

.availability-dot {
  width: 7px;
  height: 7px;
  display: block;
  border-radius: 50%;
  margin-top: 5px;
}


@media (min-width: 900px) {
  body {
    margin: 0 auto;
  }
  h1 {
    font-size: 2.5em;
    line-height: 1;
  }
}

.flims-laax-logo {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

span.companyName {
  font-size: 11px;
  color: #000;
  display: block;
}

.flims-laax-logo-link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 10;
}

.bookTicketStatusBarContainer {
  display: flex;
  flex-direction: column;
  height: 180px;
  width: 1019px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
}

.bookTicketStepsContainerTitle {
  position:relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 0.3;
  padding-top: 30px;
}
.bookTicketStepsContainerDescription {
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 70%;
  text-align: center;
  min-height: 50px;
  transition: whiteSpace 2s;
}

.statusBarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px;
}
.iconDescription {
  margin-top: 5px;
  color: #b7b7b7;
  text-align: center;
}
.line {
  width: 300px;
  height: 2px;
  background-color: #b7b7b7;
  border: none;
}

.activeRedColorText {
  color: #af272f;
  font-weight: bold;
}
.activeRedColorLine {
  background-color: #af272f;
}

/* Booking ticket steps */

.barContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin:12px;
}
.info {
  position: absolute;
  right: -30px;
  bottom: -3px;
  font-size: 16px;
  width: 20px;
  height: 20px;
  text-align: center;
  border: 2px solid black;
  border-radius: 20px;
  text-transform: none;
  cursor: pointer;
  line-height: 1.2;

}
div.info:hover{
  color: #b30019;
  border: 2px solid #b30019;
}
.bookTicketBarStep {
  border: none;
  color: #787878;
  font-size: 16px;
}
.arrowRightGrey {
  width: 10px;
  margin: 0 10px;
}

.stepActive {
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
}

@media (min-width: 900px) {
  .hideOnWeb {
    display: none;
  }
  .logoBookTicketStatusBar {
    display: block;
    position: absolute;
    right: 0;
    top: 40px;
    width: 100px;
  }
}

.icon {
  width: 20px;
}

.bookTicketStatusBarMobileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
}

.statusBarContainerMobile {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 0;
  padding-left: 16px;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 9px;
  width: 25px;
  text-transform: uppercase;
  margin: 0 19px;
}
.iconDescription {
  margin-top: 5px;
  color: #b7b7b7;
  font-size: 12px;
}

.activeRedColorText {
  color: #af272f;
  font-weight: bold;
}
.activeRedColorLine {
  background-color: #af272f;
}

/* Booking ticket steps */

.bookTicketStepsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.bookTicketStep {
  background-color: #fff;
  border: none;
  color: #787878;
  font-size: 14px;
}
.arrowRightGrey {
  width: 10px;
  margin: 0 10px;
}

.stepActive {
  font-weight: bold;
}

.subtotalMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 124px !important;
  height: 50px;
  background-color: #b30019;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  padding-top: 12px;
  font-size: 10px;
}
.subtotalPrice {
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  font-size: 16px;
}

a {
  color: #b30019;
  text-decoration: none;
}

.fixedWrapper {
  position: fixed;
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
}

abbr {
  text-decoration: none;
}

.react-calendar {
  color: grey;
  width: 315px;
  max-width: 100%;
  font-family: Roboto, sans-serif;
  line-height: 1.125em;
  background-color: #fff;
  padding-top: 10px;
  font-size: 20px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
}
.react-calendar__navigation__label__labelText {
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 18px;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 18px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #fff;
}
.react-calendar__navigation button[disabled] {
  color: #000;
}
.react-calendar__month-view__days__day {
  font-size: 14px;
  font-family: Helvetica Neue LT W05_55 Roman,sans-serif;
  color: #808080;
}

.react-calendar__month-view__weekdays {
  color: #666;
  text-align: left;
  font-weight: 100;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  font-weight: 900;
  width: 100%;
  padding: 0.75em 0.5em;
  background: none;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #fff;
}
.react-calendar__tile--now {
  background: rgba(356, 64, 42, 0.1);
}
.react-calendar__tile--hasActive {
  background: #fff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #fff;
}
.react-calendar__tile--active {
  background: #fff;
  color: #808080;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background: #fff;
}

.react-calendar__tile--range {
  background: #fff;
}

.booking-calendar-arrow-icon {
  max-width: 1em;
}

.react-calendar__tile{
  padding-right: 20px;
  padding-top: 6px;
  height: 44px;
}
.react-calendar__tile--now {
  background: #fff;
  color: #808080;
}

.dayContent {
    text-align: left;
    position: absolute;
    top:-22px;
    left:-14px;
    width:42px;
    height:42px;
    padding: 5px 3px;
    color:#000;
    border-radius: 8px;
}
.dayContainer {
    position: relative;
}

.priceContainer {
    font-size: 11px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.infoContainer{
    display: flex;
    justify-content: center;
    padding: 18px 0px;
    width: 315px;
    background-color: #fff;
    text-align: center;
    align-items: center;
}
.infoItem{
    color: #000;
    padding: 6px;
    border-radius: 16px;
    margin: 0px 2px;
    font-size: 14px;
    font-family: Helvetica Neue LT W05_55 Roman,sans-serif;
}

.amount-control {
    align-items: center;
    color: #af272fff;
    cursor: pointer;
    font-size: 30px;
}

.amount-counter-total-price {
    top: 390px;
    left: 942px;
    height: 24px;
    font: normal normal normal 16px/24px Helvetica Now Text, sans-serif;
    letter-spacing: -0.48px;
    color: #2B2B2C;
    opacity: 1;
    padding-left: 0.5em;
}
.amount {
    font-size: 28px;
    padding: 0 4px;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
    font-weight: 400;
}

.subheader {
    display: flex;
    align-items: center;
}

.subheader-icon {
    width: 2em;
    height: 2.2em;
    padding: 0 0.5em 0.5em 0;
}

.customer-type-tickets-container {
  width: 315px;
  background: #ffffff;
  padding: 6px 25px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.personTypeTitle{
  font-size: 20px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
}

.main-option-button {
  width: 36px;
  height: 36px;
  text-transform: uppercase;
  border: none;
  margin: 0;
  padding: 2px;
  font-weight: bold;
  cursor: pointer;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.container-menu{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 220px;
  box-sizing: border-box;
}
.shortMenuLine {
  width: 26px;
  height: 2px;
  background-color: #787878;
  margin: 6px 0;
}
.longMenuLine {
  width: 36px;
  height: 2px;
  background-color: #787878;
  margin-bottom: 6px;
}

.menuTitle {
  text-transform: uppercase;
  font-size: 12px;
  color: #787878;
}
.logOut {
  text-transform: uppercase;
  font-size: 14px;
  color: #787878;
  padding-left: 20px;
  cursor: pointer;
  text-decoration: underline;
}
.menuLogoContainer {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 0;
  top: 70px;
  z-index: 2;
  display: inline;
  display: initial;
}

@media (min-width: 900px) {
  .menuLogoContainer {
    display: none;
  }
  .container-menu{
    height: 140px;
  }
}


.social-media {
  display: flex;
  align-items: center;
}

.social-media-icon {
  max-width: 1.6em;
  opacity: 0.3;
  margin: 0.25em;
}

.social-media-label {
  color: #b7b7b7;
  font-family: Helvetica Neue LT W05_85 Heavy, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
}
.version-label {
  color: #b7b7b7;
  font-family: Helvetica Neue LT W05_85 Heavy, sans-serif;
  font-weight: 400;
}

.smallMenuFotterContainer {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.about {
    font-size: 34px;
    color: #b30019;
    font-weight: 900;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
}

.aboutTitle {
    padding: 20px 0px 6px;
    font-size: 16px;
    font-weight: 900;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
}
.aboutText {
    font-size: 18px;
    color: #787878;
    padding-bottom: 4px;
}

@-webkit-keyframes show {
    from {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    to {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}
@keyframes show {
    from {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
    to {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}


.toggle-menu-arrow-icon {
  width: 16px;
  padding: 0 14px;
}
.toggle-menu-options {
  display: flex;
  flex-direction: column;
}
.toggle-menu-option-border {
  height: 84px;
  width: 5px;
  background-color: #b30019;
  position: absolute;
  left: 0px;
}

.toggle-menu-option {
  width: 74%;
  color: #787878;
  font-size: 18px;
  text-transform: capitalize;
  padding-left: 46px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: left;
}

@media (min-width: 900px) {
  .toggle-menu-option {
    width: calc(100% - 46px);
  }
}

.toggleManuWithListedOptionsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.cancel-img {
  display: flex;
}

@media (min-width: 900px) {
  .cancel-img {
  display: none;
  }
}

.submit-button {
  width: 124px;
  height: 43px;
  background-color: #2b2b2c;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  position:relative;
  font-size: 16px;
  z-index: 1;
}

.submit-button-white-round {
  border-radius: 12px;
  width: 124px;
  height: 43px;
  background-color: #f5f5f5;
  color: #2b2b2c;
  border: none;
  cursor: pointer;
}

.submit-button-disabled {
  color: #fff;
  background-color: #C3C3C3;
  cursor: default;
}

.small-text {
    opacity: 0.5;
    font-size: 14px;
    font-weight: 600;
}
.small-text-link {
    text-decoration: underline;
    cursor: pointer;
}

.toggleSideMenu {
  width: 315px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.toggleMenuOptionContainer{
  border-bottom: 1px solid #F0F0F0;
}
.toggleMenuWithListedOptionsWrapper {
  padding: 20px;
  -webkit-animation: showMenu 0.5s ease-out;
          animation: showMenu 0.5s ease-out;
}
.toggleMenuDefaultViewWrapper {
  padding: 20px;
  -webkit-animation: hideMenu 0.5s ease-out;
          animation: hideMenu 0.5s ease-out;
}
@-webkit-keyframes showMenu {
  0% {
    opacity: 0;
    margin-left: -315px;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}
@keyframes showMenu {
  0% {
    opacity: 0;
    margin-left: -315px;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@-webkit-keyframes hideMenu {
  0% {
    opacity: 0;
    margin-left: -315px;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@keyframes hideMenu {
  0% {
    opacity: 0;
    margin-left: -315px;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@media (min-width: 900px) {
  .toggleSideMenu {
    margin-bottom: 20px;
  }
}

.buyTicketContainer {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  justify-content: center;
  padding-bottom: 60px;
  width:100%;
}

.buyTicketLeftSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*position: relative;*/
}
.menuContainer {
  /*position: absolute;*/
  /*top:0;*/
  /*left:0;*/
}
.menuPaddingTop{
  /*height: 205px;*/
}
.buyTicketRightSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.buyTicketRightTop {
  display: none;
}
.buyTicketRightBottom {
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
}

.active {
  display: inline;
  display: initial;
}

.disactive {
  display: none;
}

.backToPersonAbsolute {
  width: 100%;
  max-width: 350px;
  padding:0 8px;
  display: flex;
  justify-content: flex-start;
 }

.sectionLabel {
  width:100%;
  text-align: left;
  color: grey;
  font-size:15px;
  padding-bottom: 6px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  text-transform: uppercase;
}
@media (min-width: 900px) {
  .buyTicketContainer {
    display: flex;
    flex-direction: row;
    background-color: #f0f0f0;
    justify-content: center;
    max-width: 1380px;
    align-items: flex-start;
  }

  .buyTicketLeftSection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buyTicketRightSection {
    display: flex;
    flex-direction: column;
  }
  .buyTicketRightTop {
    display: inline;
    display: initial;
  }

  .buyTicketRightBottom {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-left: 25px;
  }
  .disactive {
    display: inline;
    display: initial;
    width: 315px;
  }
}

.grayed {
  opacity: 0.4;
  pointer-events: none;
}

.navigationButtonsBox {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 25px;
  position: relative;
}

.nextBackButtons {
  width: 100%;
  max-width: 350px;
  padding:0 8px;
  display: flex;
  justify-content: space-between;
}

.subtotalContainer {
  width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 25px;
  position: relative;
  padding-top: 8px;
}

.totalCostsH3 {
  font-size: 16px;
  text-transform: uppercase;
  color: #2b2b2c;
  display: flex;
}

.totalCostsText {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 2px solid black;
}
.totalCurrency {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 4px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
}
.totalPrice {
  font-size: 20px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  line-height: 1;
}
.sumTickets {
  color:#000000;
  font-weight: 900;
  font-size: 0.7em;
  line-height: 1.2;
}
.customerTickets {
  width: 100%;
  text-align: left;
}
.vatIncluded {
  display: block;
  width: 100%;
  color: #787878;
  text-align: center;
  margin-top: 8px;
}

.grayedSubTotalBox {
  opacity: 0.4;
  text-align: center;
  pointer-events: none;
  width:100px;
}

.subTotalBox {
text-align: center;
  width:100px;
}

.loginButtonContainer {
 display:none;
}
@media (min-width: 900px) {
  .subTotalBox {
    text-align: center;
    position:static;
  }
  .payButtonContainer {
    position: static;
    position: initial;
  }
  .loginButtonContainer {
    display: inline;
    display: initial;
    background-color: red;
  }
}

.addons-container {
    width: 315px;
    min-height: 168px;
    background: #ffffff;
    padding: 6px 25px 0;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.detailsContainer {
    background-color: #fff;
    position: relative;
    padding: 15px;
    width: 300px;
    color: black;
}

.detailsContainer img{
    max-width: 300px;
}

.detailsContainer strong {
    color: black;
}
.exitButtonDetails{
    position: absolute;
    right: 15px;
    top: 0;
}

.information-container {
    width: 315px;
    min-height: 168px;
    background: #ffffff;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.information-img {
    width: 315px;
    max-height: 315px;
}

.information-text{
    padding: 0 10px 10px;
    font-size: 16px;
    line-height: 1.5;
    color:grey;
    line-height: 1.5;
}

.information-text img{
    max-width: 295px;
}

.signIn-container {
    width: 315px;
    min-height: 168px;
    background: #ffffff;
    padding: 6px 25px;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.signIn-button-container {
    width: 100%;
    padding-bottom: 4px;
}

.upgrades-container {
    width: 315px;
    min-height: 168px;
    background: #ffffff;
    padding: 6px 25px 0;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.upgrades-circle {
    position: absolute;
    width: 40px;
    height: 20px;
    border: 1px solid black;
    border-radius: 15px;
    right: 0;
    cursor: pointer;
}
.upgrades-dot{
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid transparent;
    border-radius: 9px;
    transition: all  0.3s ;
}
.detailsContainer {
    background-color: #fff;
    position: relative;
    padding: 15px;
    width: 300px;
    color: black;
}

.detailsContainer img{
    max-width: 300px;
}

.detailsContainer strong {
    color: black;
}
.exitButtonDetails{
    position: absolute;
    right: 15px;
    top: 0;
}

.options-container {
    width: 315px;
    min-height: 168px;
    background: #ffffff;
    padding: 6px 18px 0;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.select-div {
    position: relative;
    float: left;
    min-width: 200px;
}
.select-div:after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAeklEQVRYhe3NsQ2AIBhEYcZxCPaxsGAUx3QAB3g2FAQQIv7ExNzVl/c5p2mapmlfj5Wl+/Fso/kAHG0CzwmE0TxtIuYZItihTSR52B8DPeJ1vk2Y5O8Js3ydMM2XhHm+IOzzGTEjXyGs8xkxI58Qs/KRmJnXNE3TfrsLRnYNSu1RPFAAAAAASUVORK5CYII=);
    color: #fff;
    right: 0px;
    top: 6px;
    height: 41px;
    border-left: 1px solid #fff;
    position: absolute;
    pointer-events: none;
}
select::-ms-expand {
    display: none;
}

.select-div select {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    width: 280px;
    height: 43px;
    float: right;
    margin: 5px 0px;
    padding: 0px 12px;
    line-height: 1.75;
    background-image: none;
    border: 1px solid #fff;
    -ms-word-break: normal;
    word-break: normal;
}

/* grey select */


.select-div-grey:after {
    background-color: rgb(0, 0, 0);
}

.select-small-div {
    position: relative;
    float: left;
    min-width: 96px;
}

.select-small-div:after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAeklEQVRYhe3NsQ2AIBhEYcZxCPaxsGAUx3QAB3g2FAQQIv7ExNzVl/c5p2mapmlfj5Wl+/Fso/kAHG0CzwmE0TxtIuYZItihTSR52B8DPeJ1vk2Y5O8Js3ydMM2XhHm+IOzzGTEjXyGs8xkxI58Qs/KRmJnXNE3TfrsLRnYNSu1RPFAAAAAASUVORK5CYII=);
    color: #000;
    right: 0px;
    top: 6px;
    height: 41px;
    border-left: 1px solid #fff;
    position: absolute;
    pointer-events: none;
}

.select-small-div select {
    -webkit-appearance: none;
    appearance: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 96px;
    height: 43px;
    margin: 5px 0px;
    padding: 0px 53px 0px 3px;
    line-height: 1.75;
    background-image: none;
    border: 1px solid #fff;
    -ms-word-break: normal;
    word-break: normal;
    text-align-last: center;
}


.addMemberContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px;
  width: 300px;
}

.keyCardTitle {
  font-size: 16px;
  font-weight: 900;
  margin-top: -2px;
}

.selectInAddMember {
  margin: 0;
  padding: 0;
}

.exitButton {
  position: absolute;
  right: 0;
  top: 0;
}

.addMemberButton {
    width: 124px;
    height: 43px;
    background-color: #2b2b2c;
    color: #fff;
    border: none;
    cursor: pointer;
    font-weight: bold;
    align-self: center;
    font-size: 16px;
}
.selectInAddMember{
    width: 100%;
    height: 50px;
    margin-bottom: 15px !important;
}
.addMemberInfo {
    margin-top: 10px;
}
.addMemberButtonContainer {
    margin-left: 80px;
    margin-bottom: 140px;
}

@media (min-width: 900px) {
    .addMemberButtonContainer {
        margin-bottom: 0px;
    }
  }
.dateBirth-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.labelBirtDate {
    font-size: 14px;
    color: #787878;
}
.keyCardInfo{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    min-height: 30px;
}
.keyCardInfoText{
    font-size: 14px;
}
.keyCardImage{
    padding-left: 2px;
    width: 40%;
    height: auto;
}
.ratioDot{
    position: absolute;
    background-color:#af272f;
    top: 15px;
    left: 9px;
    width: 3px;
    height:3px;
    border: 1px solid #af272f;
    border-radius: 3px;
}
.ratioCircle{
    position: absolute;
    top: 10px;
    width: 11px;
    height: 11px;
    border: 2px solid rgb(240, 240, 240);
    border-radius: 10px;
}

.container {
    width: 100%;
    font-size: 12px;
    padding-bottom: 6px;
}
.fieldLabel {
    font-size: 14px;
    color: #787878;
}

.child {
    width: 100%;
    position: relative;
}

.input {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: solid 2px #f0f0f0;
  outline: none;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
}
.container {
  width:100%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.inputlabel {
  font-size: 12px;
  color: #787878;
}

.inputWhite {
  width: 282px;
  padding: 10px 0;
  border: none;
  border-bottom: solid 2px #f0f0f0;
  outline: none;
  margin-bottom: 10px;
  font-size: 24p;
  font-weight: bold;
}

.inputWhite:focus {
  border-bottom: solid 2px #c0bebe;
}

.qrCodeDetectorContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;
    width: 300px;
}
.ratioCircleQrCode{
    width: 10px;
    height:10px;
    border: 2px solid rgb(240, 240, 240);
    border-radius: 10px;
    margin-right:10px;
}
.ratioDotQrCode{
    margin: 2.5px;
    background-color:#af272f;
    width: 3px;
    height:3px;
    border: 1px solid #af272f;
    border-radius: 3px;
}

.tileContainerWithChildren {
  padding: 0 1em;
  width: 315px;
  box-sizing: border-box;
  color: #787878;
  font-size: 12px;
  background-color: #fff;
  padding-top: 2em;
  padding-bottom: 1em;
}

.tileContainerTitle {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.tileContainerText {
  font-size: 12px;
}

.dotInfoContainer {
  display: flex;
  line-height: 1.5;

}
.dot {
  font-size: 30px;
}
.dotInfo {
  margin-left: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.tileWithDots {
  padding: 8px 0;

}

@media (min-width: 900px) {
  .tileContainerWithChildren {
    padding-top: 0;
    padding-top: initial;
    padding-bottom: 0;
    padding-bottom: initial;
  }
}

.ticketSummaryContainer {
  background-color: #fff;
  width: 315px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 25px;
  margin-top: 25px;
}
.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.itemSubHeader {
  width:100%;
}
.ticketSubHeader {
  width:100%;
}
.itemPrice {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 130px;
}
@media (min-width: 900px) {
  .ticketSummaryContainer {
    padding: 10px 20px;
    width: 655px;
    margin-top: 0;
  }
  .itemPrice {
    justify-content: center;
  }
  .itemSubHeader {
    width:64%;
  }
  .ticketSubHeader {
    width:64%;
  }
}


.bigTicketPriceMobile {
    display: inline;
    display: initial;
    position: absolute;
    top: -36px;
    right: 20px;
}
.bigTicketPriceWeb {
    display: none;
}
.addPersonText {
    display: flex;
    font-size: 14px;
    font-family: Helvetica Neue LT W05_55 Roman,sans-serif;
    opacity: 0.5;
    text-decoration: underline;
    cursor: pointer;
}
.checkoutTicketPrice{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 130px;
}

.closeButton{
    width: 16px;
}
.pointer{
    cursor: pointer;
}
.selectRightContainer{
    width: 100%;
    display: flex;
    flexD-drection: row;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 900px) {

    .bigTicketPriceWeb {
        display: inline;
        display: initial;
        margin-bottom: 4px;
    }
    .bigTicketPriceMobile {
        display: none;
    }
    .select{
        width:300px;
    }
    .ticketPrice{
        display: flex;
        flex-direction: column;
    }
    .selectRightContainer{
        width: 50%;
    }
}

.summaryTicketContainer {
  flex-direction: column;
  width: 280px;
  align-items: center;
  border-bottom: 2px solid #f0f0f0;
}

.personAndPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 280px;
}

@media (min-width: 900px) {
  .summaryTicketContainer {
    flex-direction: row;
    width: 100%;
  }
}

a {
  color: #787878;
}

.nextBackButtonsCheckout {
  width: 100%;
  max-width: 350px;
  padding:0 8px;
  display: flex;
  justify-content: space-between;
}

.linkToStep1 {
  font-size: 20px;
  cursor: pointer;
}

.pay-button {
  width: 124px;
  height: 43px;
  background-color: #2b2b2c;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.pay-button-disabled {
  color: #fff;
  background-color: #c3c3c3;
  cursor: default;
}
.acceptCheckbox {
  margin: 15px 0;
}

input {
  margin-right: 10px;
}
.checkbox {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  color: #2b2b2c;
}
.checkboxSpan {
  padding-top: 1px;
  text-decoration: underline;
  margin-left: 5px;
  font-size: 16px;
}

label.checkbox {
  font-size: 16px;
}

.checkbox > input {
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  transition-duration: 0.3s;
  background-color: #ffffff;
  border: 2px solid #2b2b2c;
  cursor: pointer;
}

.checkbox > input:checked {
  background-color: #2b2b2c;
}

.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 6px;
  top: 2px;
  font-weight: bold;
}

.checkoutTotalContainer {
  width: 285px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 25px;
  position: relative;
  padding: 10px 15px 25px;
}

h3 {
  font-size: 18px;
  text-transform: uppercase;
  color: #2b2b2c;
  display: flex;
}

.dollarIcon {
  fill: #b30019;
  height: 20px;
  font-weight: bold;
  align-self: center;
}
.costsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.currency {
  color: #b30019;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-right: 10px;
}
.price {
  color: #b30019;
  font-size: 52px;
  font-weight: bold;
  line-height: 1;
}
.discountCurrency{
  font-size: 14px;
  padding-right:6px;
  padding-top:4px;
}
.discountPrice{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 50px;
  right: 50px;
  color: #b30019;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
}
.discountLine{
  position: absolute;
  background-color: #b30019;
  height: 1px;
  width: 100%;
}
span.vat {
  display: block;
  width: 100%;
  color: #787878;
  text-align: center;
  margin-top: 8px;
}

.grayedTotalBox {
  opacity: 0.4;
  text-align: center;
  pointer-events: none;
}

.totalBox {
text-align: center;
}
.payButtonContainer {
 display: none;
}
.loginButtonContainer {
  position: absolute;
  bottom: -75px;
  right: -16px;
}
.addToken {
  width:100%;
  display: flex;
  align-self: flex-start;
  font-size: 16px;
  font-family: Helvetica Neue LT W05_55 Roman,sans-serif;
  opacity: 0.5;
  text-decoration: underline;
  cursor: pointer;
  margin: 10px 0 20px;
}
@media (min-width: 900px) {
  .totalBox {
    text-align: center;
    position:static;
  }
  .payButtonContainer {
    display: inline;
    display: initial;
  }
  .loginButtonContainer {
    position: static;
    position: initial;
  }
}

.select-div-2 {
  position: relative;
  float: left;
}

.select-arrow:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAeklEQVRYhe3NsQ2AIBhEYcZxCPaxsGAUx3QAB3g2FAQQIv7ExNzVl/c5p2mapmlfj5Wl+/Fso/kAHG0CzwmE0TxtIuYZItihTSR52B8DPeJ1vk2Y5O8Js3ydMM2XhHm+IOzzGTEjXyGs8xkxI58Qs/KRmJnXNE3TfrsLRnYNSu1RPFAAAAAASUVORK5CYII=);
  color: #fff;
  right: 0px;
  top: 5px;
  height: 43px;
  border-left: 1px solid #fff;
  position: absolute;
  pointer-events: none;
}

.select-calendar:after {
  content: url(/static/media/calendar-date-white.de2d2894.svg);
  color: #fff;
  right: 0px;
  top: 5px;
  height: 27px;
  padding:8px 10px;
  border-left: 1px solid #fff;
  position: absolute;
  pointer-events: none;
}

.select-people:after {
  content: url(/static/media/user-white.1f775edd.svg);
  color: #fff;
  right: 0px;
  top: 5px;
  height: 23px;
  padding:10px 4px 10px 12px;
  border-left: 1px solid #fff;
  position: absolute;
  pointer-events: none;
}
select::-ms-expand {
  display: none;
}

.select-div-2 select {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  height: 43px;
  float: right;
  margin: 5px 0px;
  padding: 0px 5px;
  font-size: 12px;
  line-height: 1.75;
  color: #fff;
  background-color: #2b2b2c;
  background-image: none;
  border: 1px solid #fff;
  -ms-word-break: normal;
  word-break: normal;
}

/* grey select */

.select-div-grey-2:after {
  background-color: rgb(0, 0, 0);
}

.exitButton {
    position: absolute;
    right: 0;
    top: 0;
}
.center {
    display: flex;
    justify-content: center;
}
.activeTokenContainer {
    position: relative;
    height: 600px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.confirmKeyCardContainer {
  width: auto;
  position: relative;
}


@media (min-width: 900px) {
  .confirmKeyCardContainer {
     width: 500px;
  }
}

.cookies-panel {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #2b2b2c;
  padding: 1em;
  z-index: 1;
}

.more-info {
  text-decoration: underline;
}
.mainPictureContainer {
  width: 993px;
  height: 468px;
  position: relative;
  display: none;
}

.mainPicture {
  width: 993px;
  height: 100%;
}

@media (min-width: 900px) {
  .mainPictureContainer {
    display: block;
  }
  .logoContainerStartPicture {
    position: absolute;
    right: 0;
    top: 40px;
    width: 100px;
  }
}

.downloadAppContainer {
  width: 312px;
  height: 466px;
  position: relative;
  background-color: #b30019;
}
.downloadAppTitle {
  color: #fff;
  font-size: 33px;
  box-sizing: border-box;
  padding: 25px;
}
@media (min-width: 900px) {
  .downloadAppPhoneIMG {
    position: absolute;
    bottom: 0;
    right: -15px;
    width: 200px;
  }
}
@media (min-width: 1px) and (max-width: 1379px) {
  .downloadAppPhoneIMG {
    position: absolute;
    bottom: 0;
    right: -15px;
    width: 180px;
  }
}
.downloadAppInsideIMG {
  position: absolute;
  width: 80px;
  bottom: 100px;
  left: 25px;
}

.downloadAppAppleIMG,
.downloadAppAndroidIMG {
  position: absolute;
  bottom: 30px;
  left: 15px;
  width: 40px;
}
.downloadAppAndroidIMG {
  left: 68px;
}
.downloadAppButton {
  position: absolute;
  top: 190px;
  left: 25px;
  border: none;
  color: #b30019;
  background-color: #fff;
  height: 40px;
  width: 135px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  box-sizing: border-box;
  padding-top: 5px;
}

.downloadAppButton > a {
  width: 100%;
  height: 100%;
  line-height: 2.5;
  display: block;
  color: #b30019;
}

@media (min-width: 900px) {
  .downloadAppContainer {
    width: 322px;
  }
}

.descriptionContainer {
  width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  background-color: #fff;
}

h3.descriptionH3 {
  font-size: 18px;
  font-weight: 900;
  color: #2b2b2c;
  align-self: flex-start;
}
p {
  align-self: center;
  color: #787878;
  font-size: 16px;
  line-height: 1.5;
}
.line {
  display: block;
  width: 290px;
  border: 1px solid #b7b7b7;
  color: #787878;
}
.laaxLink {
  display: block;
  margin-top: 10px;
  text-align: center;
  color: #787878;
}

.start-view-container {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  max-width: 380px;
}

.leftSectionWeb {
  display: none;
}
.hideOnMobile {
  display: none;
}

.logoOnMobile {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
}

.successPageTickets {
  margin: 10px 13px;
}

.successPageTitle {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  color: #2b2b2c;
  padding-top: 20px;
}

.successPageHeader {
  width: 994px;
  height: 180px;
  background-color: #fff;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  display: none;
}

.successPageTitleBig {
  font-size: 50px;
  color: #b30019;
  font-weight: 800;
  margin-left: 25px;
  line-height: 1;
}
.tileWithSelect {
  background-color: #fff;
  height: 220px;
}
.rightSectionWeb {
  display: none;
}
@media (min-width: 900px) {
  .start-view-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-top: 0;
    max-width: 1380px;
  }
  .leftSectionWeb {
    display: flex;
    flex-direction: column;
    max-width: 30%;
  }
  .leftSectionMobile {
    display: none;
  }
  .tileContainer {
    padding-top: 22px;
    padding-left: 16px;
    width: 100%;
  }
  .rightSectionWeb {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1145px) and (max-width: 1250px) {
    .rightSectionWeb {
      padding-left: 5vw;
    }
  }

  @media (min-width: 1000px) and (max-width: 1144px) {
    .rightSectionWeb {
      padding-left: 5vw;
    }
  }

  @media (min-width: 1000px) and (max-width: 1144px) {
    .rightSectionWeb {
      padding-left: 6vw;
    }
  }

  @media (min-width: 900px) and (max-width: 999px) {
    .rightSectionWeb {
      padding-left: 9vw;
    }
  }

  .rightTop {
    width: 97%;
    margin: 0 auto;
    padding-bottom:10px;
    align-self: center;
  }
  .rightBottom {
    width: 1008px;
    display: flex;
  }
  .hideOnMobile {
    display: inline;
    display: initial;
    width: 31%;
  }
  .successPageHeader {
    display: flex;
  }
}


.categories-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:100%;
    margin: 5px 0;
}

.category-container{
    min-width:48%;
    text-align: center;
    margin-top:10px;
    padding:16px 0;
    background-color: white;
    cursor: pointer;
}

.small-main-view-tile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.small-main-view-tile-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
}

@media (min-width: 900px) {
  .small-main-view-tile-container {
    width: 100%;
  }
}
.small-main-view-tile-img {
  height: 164px;
  background-size: cover;
}
.dots-menu-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.small-main-view-title {
  height:70px;
  display: flex;
  align-items: center;
  padding-left:10px;
  cursor: pointer;
}

.small-main-view-description {
  color: #787878;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  line-height: 1.5;
}

.small-main-view-footer {
  width: 100%;
  color: #787878;
}
.small-main-view-footer > span {
  display: inline-block;
  width: 100%;
  text-align: center;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  margin-top: 20px;
  font-size: 14px;
}

.carousel-arrow-back{
    position: absolute;
    left:0;
    display:flex;
    height:200px;
    opacity: 0.7;
    background-color: white ;
    padding:10px;
    align-items: center;
    top:0;
    z-index: 10;
}

.carousel-arrow-next{
    position: absolute;
    right:0;
    display:flex;
    height:200px;
    padding:10px;
    opacity: 0.7;
    background-color: white ;
    align-items: center;
    top:0;
    z-index: 10;
}
.clean-navigation-button{
    border:none;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.carouselModal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.61);

}

.ticketMoreDetailsContainer {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-items: stretch;
  justify-content: stretch;
}

.moreDetailsExit {
  position: absolute;
  right: 0;
  top: 0;
}

.moreDetailsButtons{
  width: 100%;
  margin-bottom: 6px;
}

.ticketButtonContainer{
  display: flex;
  justify-content: flex-start;
  text-align: start;
  align-items: center;
}



.userPanelContainer {
  display: flex;
  justify-content: space-between;
  width: 315px;
  box-sizing: border-box;
  margin: 0 25px;
  flex-direction: column;
}
.userPanelMenu {
  background-color: #fff;
}

.avatar {
  width: 222px;
  height: 222px;
  background-color: cadetblue;
  margin: 36px;
  box-sizing: border-box;
}

.userPanelRightSection {
  display: flex;
  flex-direction: column;
  width: 315px;
  margin-top: 25px;
  margin-left: 0px;
}

.userPanelTopRight {
  background-color: #fff;
  width: 994px;
  height: 225px;
  margin-bottom: 25px;
  display: none;
}

.userPanelBottomRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userPanelBox {
  width: 315px;
  height: 468px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.userProfileLine {
  display: block;
  width: 290px;
  border-bottom: solid 2px #f0f0f0;
  color: #787878;
}

@media (min-width: 900px) {
  .userPanelContainer {
    display: flex;
    justify-content: space-between;
    width: 1380px;
    box-sizing: border-box;
    padding: 0 25px;
    flex-direction: row;
  }
  .userPanelMenu {
    background-color: #fff;
  }

  .avatar {
    width: 222px;
    height: 222px;
    background-color: cadetblue;
    margin: 36px;
    box-sizing: border-box;
  }

  .userPanelRightSection {
    display: flex;
    flex-direction: column;
    width: 994px;
    margin-top: 0px;
    margin-left: 25px;
  }

  .userPanelTopRight {
    background-color: #fff;
    width: 994px;
    height: 225px;
    margin-bottom: 25px;
    display: inline;
    display: initial;
  }

  .userPanelBottomRight {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .userPanelBox {
    width: 315px;
    height: 468px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
    margin-bottom: 0;
  }

  .userProfileLine {
    display: block;
    width: 290px;
    border-bottom: solid 2px #f0f0f0;
    color: #787878;
  }
}

.userProfileStatusBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 225px;
  width: 994px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
}

.userPanelTitle {
  position:relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 0.3;
  padding-top: 40px;
}

.userPanelInfo {
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 70%;
  text-align: center;
  min-height: 50px;
  transition: whiteSpace 2s;
}

.statusBarContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px;
}
.iconText {
  margin-top: 5px;
  color: #af272f;
  text-align: center;
}
.line {
  width: 300px;
  height: 2px;
  background-color: #af272f;
  border: none;
}

.activeRedColorText {
  color: #af272f;
  font-weight: bold;
}
.activeRedColorLine {
  background-color: #af272f;
}

/* Booking ticket steps */

.bookTicketStepsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.bookTicketStep {
  background-color: #fff;
  border: none;
  color: #787878;
  font-size: 14px;
}
.arrowRightGrey {
  width: 10px;
  margin: 0 10px;
}

.stepActive {
  font-weight: bold;
}

@media (min-width: 900px) {
  .hideOnWeb {
    display: none;
  }
}

.TicketLine {
    display: block;
    border-bottom: solid 2px #f0f0f0;
    color: #787878;
}

.yourProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonInYourProfile {
  margin-top: 15px;
  z-index: 0;
}

.addPersonButton {
  display: flex;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
  margin-top: 80px; 
}
.addPerson {
  margin-right: 10px;
  width:25px;
}

.groupMember {
  width: 300px;
  height:420px;
  overflow-y: auto;
  overflow-x: hidden;
}


.rowContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  line-height: 1.6;
  padding: 12px 0 0;
  position: relative;
}

.leftSectionText {
  display: flex;
  flex-direction: column;
  color: #787878;
}
.keyCardNumber {
  color: #787878;
}
.dateInBookedTicket {
  color: #2b2b2c;
  font-size: 16px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  width: 200px;
  max-width: 200px;
  overflow-x: auto;
}

.rightSectionIcon {
  cursor: pointer;
  align-self: flex-start;
  width: 50px;
  max-width: 50px;
  min-width: 50px;
}
.rightSectionIcon > img {
  width: 18px;
}

.userProfileLine {
  display: block;
  width: 290px;
  padding-top: 12px;
  border-bottom: solid 2px #f0f0f0;
  color: #787878;
}

.closeButtonUserProfile {
  margin-left: 10px;
  width: 15px !important;
}

.editMemberContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px;
  width: 300px;
}
.keyCardTitle {
  font-size: 16px;
  font-weight: 900;
  margin-top: -2px;
}

.selectInAddMember {
  margin: 0;
  padding: 0;
}

.exitButtonEdit {
  position: absolute;
  right: 10px;
  top: 0;
}

.removeMemberModalContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;
    width: 300px;
    height: 94%;
}

.removeModalInfo {
    height: 110px;
}
.removeModalButtonsContainer {
    display: flex;
    justify-content: space-around;
    margin-top:auto;
}

.noAccessContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #787878;
    font-size: 24px;
}

.wrapper-widget-container{
    position: relative;
    width: 100%;
}
.widget-container {
    position: absolute;
    bottom:0;
    width: calc(100% - 40px);
    height: 140px;
    padding: 0px 20px;
    color: #ffffff;
    transition: height 0.5s;
    overflow: hidden;
}
.product-name {
    width: 100%;
    padding: 20px 0 10px;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 1;
 }
.inputs {
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    transition: opacity 0.2s;
    transition-timing-function: ease-in;
    padding-bottom: 10px;
}
.product-details {
    width: calc(100% -  40px);
    position: absolute;
    bottom: 0px;
    right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}
.submit-button-white{
    border: none;
    cursor: pointer;
    position:relative;
    z-index: 1;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
    font-size: 22px;
    padding: 10px 20px;
}

.select-date-modal {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;

}

.input-date {
    background-color: rgb(240, 240, 240);
    display: flex;
    color: black;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

/*Mobile css*/

.widget-container-mobile {
    position: absolute;
    bottom:0;
    width: calc(100% - 20px);
    height: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 10px;
    color: #ffffff;
    transition: height 0.5s;
    align-items: center;
    overflow: hidden;
}

.product-name-mobile {
    width: 50%;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
    font-weight: 400;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.inputs-mobile {
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    transition: opacity 0.5s;
    padding-bottom: 55px;
    align-items: center;
}

.product-details-mobile {
    position: absolute;
    bottom: 0px;
    left: 10px;
    padding-bottom: 5px;
    transition: opacity 0.5s,height .5s;
}
.submit-button-white-mobile{
    border: none;
    cursor: pointer;
    position:relative;
    z-index: 1;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    transition: opacity 0.2s;
}

.payment-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.payment-close {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: unset;
  border: unset;
  cursor: pointer;
}

.payment-close > img {
  width: 40px;
  height: 40px;
}

.payment-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.payment-iframe {
  z-index: 200;
  background-color: white;
  border: 0;
}

@media (max-width: 899px) {
  .payment-iframe {
    width: 300%;
    height: 165%;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
}

@media (min-width: 900px) {
  .payment-iframe {
    width: 90%;
    height: 90%;
  }
}
.noAccessContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    color: #787878;
    font-size: 24px;
}

.offlineInfo {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgb(255,255,255);
    opacity:0.8;
    padding: 50px 10px;
}

.offlineImage{
    width:  1400px;
    height: 99.4vh;
}
@media (min-width: 1400px) {
    .offlineImage{
        width: 100vw;
    }
}

