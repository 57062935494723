.social-media {
  display: flex;
  align-items: center;
}

.social-media-icon {
  max-width: 1.6em;
  opacity: 0.3;
  margin: 0.25em;
}

.social-media-label {
  color: #b7b7b7;
  font-family: Helvetica Neue LT W05_85 Heavy, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
}
.version-label {
  color: #b7b7b7;
  font-family: Helvetica Neue LT W05_85 Heavy, sans-serif;
  font-weight: 400;
}
