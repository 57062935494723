.smallMenuFotterContainer {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
}

.about {
    font-size: 34px;
    color: #b30019;
    font-weight: 900;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
}

.aboutTitle {
    padding: 20px 0px 6px;
    font-size: 16px;
    font-weight: 900;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
}
.aboutText {
    font-size: 18px;
    color: #787878;
    padding-bottom: 4px;
}
