.ticketSummaryContainer {
  background-color: #fff;
  width: 315px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 25px;
  margin-top: 25px;
}
.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.itemSubHeader {
  width:100%;
}
.ticketSubHeader {
  width:100%;
}
.itemPrice {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 130px;
}
@media (min-width: 900px) {
  .ticketSummaryContainer {
    padding: 10px 20px;
    width: 655px;
    margin-top: 0;
  }
  .itemPrice {
    justify-content: center;
  }
  .itemSubHeader {
    width:64%;
  }
  .ticketSubHeader {
    width:64%;
  }
}
