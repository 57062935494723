.dayContent {
    text-align: left;
    position: absolute;
    top:-22px;
    left:-14px;
    width:42px;
    height:42px;
    padding: 5px 3px;
    color:#000;
    border-radius: 8px;
}
.dayContainer {
    position: relative;
}

.priceContainer {
    font-size: 11px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.infoContainer{
    display: flex;
    justify-content: center;
    padding: 18px 0px;
    width: 315px;
    background-color: #fff;
    text-align: center;
    align-items: center;
}
.infoItem{
    color: #000;
    padding: 6px;
    border-radius: 16px;
    margin: 0px 2px;
    font-size: 14px;
    font-family: Helvetica Neue LT W05_55 Roman,sans-serif;
}
