.tileContainerWithChildren {
  padding: 0 1em;
  width: 315px;
  box-sizing: border-box;
  color: #787878;
  font-size: 12px;
  background-color: #fff;
  padding-top: 2em;
  padding-bottom: 1em;
}

.tileContainerTitle {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.tileContainerText {
  font-size: 12px;
}

.dotInfoContainer {
  display: flex;
  line-height: 1.5;

}
.dot {
  font-size: 30px;
}
.dotInfo {
  margin-left: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.tileWithDots {
  padding: 8px 0;

}

@media (min-width: 900px) {
  .tileContainerWithChildren {
    padding-top: initial;
    padding-bottom: initial;
  }
}
