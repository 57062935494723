.customer-type-tickets-container {
  width: 315px;
  background: #ffffff;
  padding: 6px 25px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.personTypeTitle{
  font-size: 20px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
}
