.exitButton {
    position: absolute;
    right: 0;
    top: 0;
}
.center {
    display: flex;
    justify-content: center;
}
.activeTokenContainer {
    position: relative;
    height: 600px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}
