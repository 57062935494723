.userProfileStatusBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 225px;
  width: 994px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
}

.userPanelTitle {
  position:relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 0.3;
  padding-top: 40px;
}

.userPanelInfo {
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 70%;
  text-align: center;
  min-height: 50px;
  transition: whiteSpace 2s;
}

.statusBarContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px;
}
.iconText {
  margin-top: 5px;
  color: #af272f;
  text-align: center;
}
.line {
  width: 300px;
  height: 2px;
  background-color: #af272f;
  border: none;
}

.activeRedColorText {
  color: #af272f;
  font-weight: bold;
}
.activeRedColorLine {
  background-color: #af272f;
}

/* Booking ticket steps */

.bookTicketStepsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.bookTicketStep {
  background-color: #fff;
  border: none;
  color: #787878;
  font-size: 14px;
}
.arrowRightGrey {
  width: 10px;
  margin: 0 10px;
}

.stepActive {
  font-weight: bold;
}

@media (min-width: 900px) {
  .hideOnWeb {
    display: none;
  }
}
