.offlineInfo {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgb(255,255,255);
    opacity:0.8;
    padding: 50px 10px;
}

.offlineImage{
    width:  1400px;
    height: 99.4vh;
}
@media (min-width: 1400px) {
    .offlineImage{
        width: 100vw;
    }
}
