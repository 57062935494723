.toggleSideMenu {
  width: 315px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.toggleMenuOptionContainer{
  border-bottom: 1px solid #F0F0F0;
}
.toggleMenuWithListedOptionsWrapper {
  padding: 20px;
  animation: showMenu 0.5s ease-out;
}
.toggleMenuDefaultViewWrapper {
  padding: 20px;
  animation: hideMenu 0.5s ease-out;
}
@keyframes showMenu {
  0% {
    opacity: 0;
    margin-left: -315px;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@keyframes hideMenu {
  0% {
    opacity: 0;
    margin-left: -315px;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@media (min-width: 900px) {
  .toggleSideMenu {
    margin-bottom: 20px;
  }
}
