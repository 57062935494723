.summaryTicketContainer {
  flex-direction: column;
  width: 280px;
  align-items: center;
  border-bottom: 2px solid #f0f0f0;
}

.personAndPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 280px;
}

@media (min-width: 900px) {
  .summaryTicketContainer {
    flex-direction: row;
    width: 100%;
  }
}
