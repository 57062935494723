.information-container {
    width: 315px;
    min-height: 168px;
    background: #ffffff;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.information-img {
    width: 315px;
    max-height: 315px;
}

.information-text{
    padding: 0 10px 10px;
    font-size: 16px;
    line-height: 1.5;
    color:grey;
    line-height: 1.5;
}

.information-text img{
    max-width: 295px;
}
