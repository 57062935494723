.addons-container {
    width: 315px;
    min-height: 168px;
    background: #ffffff;
    padding: 6px 25px 0;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.detailsContainer {
    background-color: #fff;
    position: relative;
    padding: 15px;
    width: 300px;
    color: black;
}

.detailsContainer img{
    max-width: 300px;
}

.detailsContainer strong {
    color: black;
}
.exitButtonDetails{
    position: absolute;
    right: 15px;
    top: 0;
}
