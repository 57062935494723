a {
  color: #787878;
}

.nextBackButtonsCheckout {
  width: 100%;
  max-width: 350px;
  padding:0 8px;
  display: flex;
  justify-content: space-between;
}

.linkToStep1 {
  font-size: 20px;
  cursor: pointer;
}
