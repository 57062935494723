.select-div {
    position: relative;
    float: left;
    min-width: 200px;
}
.select-div:after {
    content: url('../../../icons/arrow-simple-down-white.png');
    color: #fff;
    right: 0px;
    top: 6px;
    height: 41px;
    border-left: 1px solid #fff;
    position: absolute;
    pointer-events: none;
}
select::-ms-expand {
    display: none;
}

.select-div select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 280px;
    height: 43px;
    float: right;
    margin: 5px 0px;
    padding: 0px 12px;
    line-height: 1.75;
    background-image: none;
    border: 1px solid #fff;
    -ms-word-break: normal;
    word-break: normal;
}

/* grey select */


.select-div-grey:after {
    background-color: rgb(0, 0, 0);
}

.select-small-div {
    position: relative;
    float: left;
    min-width: 96px;
}

.select-small-div:after {
    content: url('../../../icons/arrow-simple-down-white.png');
    color: #000;
    right: 0px;
    top: 6px;
    height: 41px;
    border-left: 1px solid #fff;
    position: absolute;
    pointer-events: none;
}

.select-small-div select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 96px;
    height: 43px;
    margin: 5px 0px;
    padding: 0px 53px 0px 3px;
    line-height: 1.75;
    background-image: none;
    border: 1px solid #fff;
    -ms-word-break: normal;
    word-break: normal;
    text-align-last: center;
}

