.acceptCheckbox {
  margin: 15px 0;
}

input {
  margin-right: 10px;
}
.checkbox {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  color: #2b2b2c;
}
.checkboxSpan {
  padding-top: 1px;
  text-decoration: underline;
  margin-left: 5px;
  font-size: 16px;
}

label.checkbox {
  font-size: 16px;
}

.checkbox > input {
  height: 18px;
  width: 18px;
  appearance: none;
  outline: none;
  transition-duration: 0.3s;
  background-color: #ffffff;
  border: 2px solid #2b2b2c;
  cursor: pointer;
}

.checkbox > input:checked {
  background-color: #2b2b2c;
}

.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 6px;
  top: 2px;
  font-weight: bold;
}
