.addPersonButton {
  display: flex;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
  margin-top: 80px; 
}
.addPerson {
  margin-right: 10px;
  width:25px;
}
