.inputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.inputlabel {
  font-size: 12px;
  color: #787878;
}

.inputWhite {
  width: 282px;
  padding: 10px 0;
  border: none;
  border-bottom: solid 2px #f0f0f0;
  outline: none;
  margin-bottom: 10px;
  font-size: 24p;
  font-weight: bold;
}

.inputWhite:focus {
  border-bottom: solid 2px #c0bebe;
}
