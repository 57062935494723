.mainPictureContainer {
  width: 993px;
  height: 468px;
  position: relative;
  display: none;
}

.mainPicture {
  width: 993px;
  height: 100%;
}

@media (min-width: 900px) {
  .mainPictureContainer {
    display: block;
  }
  .logoContainerStartPicture {
    position: absolute;
    right: 0;
    top: 40px;
    width: 100px;
  }
}
