.toggle-menu-arrow-icon {
  width: 16px;
  padding: 0 14px;
}
.toggle-menu-options {
  display: flex;
  flex-direction: column;
}
.toggle-menu-option-border {
  height: 84px;
  width: 5px;
  background-color: #b30019;
  position: absolute;
  left: 0px;
}

.toggle-menu-option {
  width: 74%;
  color: #787878;
  font-size: 18px;
  text-transform: capitalize;
  padding-left: 46px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: left;
}

@media (min-width: 900px) {
  .toggle-menu-option {
    width: calc(100% - 46px);
  }
}
