.select-div-2 {
  position: relative;
  float: left;
}

.select-arrow:after {
  content: url('../../../icons/arrow-simple-down-white.png');
  color: #fff;
  right: 0px;
  top: 5px;
  height: 43px;
  border-left: 1px solid #fff;
  position: absolute;
  pointer-events: none;
}

.select-calendar:after {
  content: url('../../../icons/calendar-date-white.svg');
  color: #fff;
  right: 0px;
  top: 5px;
  height: 27px;
  padding:8px 10px;
  border-left: 1px solid #fff;
  position: absolute;
  pointer-events: none;
}

.select-people:after {
  content: url('../../../icons/user-white.svg');
  color: #fff;
  right: 0px;
  top: 5px;
  height: 23px;
  padding:10px 4px 10px 12px;
  border-left: 1px solid #fff;
  position: absolute;
  pointer-events: none;
}
select::-ms-expand {
  display: none;
}

.select-div-2 select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  height: 43px;
  float: right;
  margin: 5px 0px;
  padding: 0px 5px;
  font-size: 12px;
  line-height: 1.75;
  color: #fff;
  background-color: #2b2b2c;
  background-image: none;
  border: 1px solid #fff;
  -ms-word-break: normal;
  word-break: normal;
}

/* grey select */

.select-div-grey-2:after {
  background-color: rgb(0, 0, 0);
}
