.bookTicketStatusBarContainer {
  display: flex;
  flex-direction: column;
  height: 180px;
  width: 1019px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
}

.bookTicketStepsContainerTitle {
  position:relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 0.3;
  padding-top: 30px;
}
.bookTicketStepsContainerDescription {
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  font-size: 14px;
  width: 70%;
  text-align: center;
  min-height: 50px;
  transition: whiteSpace 2s;
}

.statusBarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px;
}
.iconDescription {
  margin-top: 5px;
  color: #b7b7b7;
  text-align: center;
}
.line {
  width: 300px;
  height: 2px;
  background-color: #b7b7b7;
  border: none;
}

.activeRedColorText {
  color: #af272f;
  font-weight: bold;
}
.activeRedColorLine {
  background-color: #af272f;
}

/* Booking ticket steps */

.barContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin:12px;
}
.info {
  position: absolute;
  right: -30px;
  bottom: -3px;
  font-size: 16px;
  width: 20px;
  height: 20px;
  text-align: center;
  border: 2px solid black;
  border-radius: 20px;
  text-transform: none;
  cursor: pointer;
  line-height: 1.2;

}
div.info:hover{
  color: #b30019;
  border: 2px solid #b30019;
}
.bookTicketBarStep {
  border: none;
  color: #787878;
  font-size: 16px;
}
.arrowRightGrey {
  width: 10px;
  margin: 0 10px;
}

.stepActive {
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
}

@media (min-width: 900px) {
  .hideOnWeb {
    display: none;
  }
  .logoBookTicketStatusBar {
    display: block;
    position: absolute;
    right: 0;
    top: 40px;
    width: 100px;
  }
}
