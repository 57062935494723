.input {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: solid 2px #f0f0f0;
  outline: none;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
}
.container {
  width:100%;
}
