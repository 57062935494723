.descriptionContainer {
  width: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  background-color: #fff;
}

h3.descriptionH3 {
  font-size: 18px;
  font-weight: 900;
  color: #2b2b2c;
  align-self: flex-start;
}
p {
  align-self: center;
  color: #787878;
  font-size: 16px;
  line-height: 1.5;
}
.line {
  display: block;
  width: 290px;
  border: 1px solid #b7b7b7;
  color: #787878;
}
.laaxLink {
  display: block;
  margin-top: 10px;
  text-align: center;
  color: #787878;
}
