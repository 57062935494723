.addMemberButton {
    width: 124px;
    height: 43px;
    background-color: #2b2b2c;
    color: #fff;
    border: none;
    cursor: pointer;
    font-weight: bold;
    align-self: center;
    font-size: 16px;
}
.selectInAddMember{
    width: 100%;
    height: 50px;
    margin-bottom: 15px !important;
}
.addMemberInfo {
    margin-top: 10px;
}
.addMemberButtonContainer {
    margin-left: 80px;
    margin-bottom: 140px;
}

@media (min-width: 900px) {
    .addMemberButtonContainer {
        margin-bottom: 0px;
    }
  }
.dateBirth-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.labelBirtDate {
    font-size: 14px;
    color: #787878;
}
.keyCardInfo{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    min-height: 30px;
}
.keyCardInfoText{
    font-size: 14px;
}
.keyCardImage{
    padding-left: 2px;
    width: 40%;
    height: auto;
}
.ratioDot{
    position: absolute;
    background-color:#af272f;
    top: 15px;
    left: 9px;
    width: 3px;
    height:3px;
    border: 1px solid #af272f;
    border-radius: 3px;
}
.ratioCircle{
    position: absolute;
    top: 10px;
    width: 11px;
    height: 11px;
    border: 2px solid rgb(240, 240, 240);
    border-radius: 10px;
}
