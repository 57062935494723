.amount-counter-total-price {
    top: 390px;
    left: 942px;
    height: 24px;
    font: normal normal normal 16px/24px Helvetica Now Text, sans-serif;
    letter-spacing: -0.48px;
    color: #2B2B2C;
    opacity: 1;
    padding-left: 0.5em;
}
.amount {
    font-size: 28px;
    padding: 0 4px;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
    font-weight: 400;
}
