.yourProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonInYourProfile {
  margin-top: 15px;
  z-index: 0;
}
