.buyTicketContainer {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  justify-content: center;
  padding-bottom: 60px;
  width:100%;
}

.buyTicketLeftSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*position: relative;*/
}
.menuContainer {
  /*position: absolute;*/
  /*top:0;*/
  /*left:0;*/
}
.menuPaddingTop{
  /*height: 205px;*/
}
.buyTicketRightSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.buyTicketRightTop {
  display: none;
}
.buyTicketRightBottom {
  display: flex;
  justify-content: space-between;
  margin-left: 0px;
}

.active {
  display: initial;
}

.disactive {
  display: none;
}

.backToPersonAbsolute {
  width: 100%;
  max-width: 350px;
  padding:0 8px;
  display: flex;
  justify-content: flex-start;
 }

.sectionLabel {
  width:100%;
  text-align: left;
  color: grey;
  font-size:15px;
  padding-bottom: 6px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  text-transform: uppercase;
}
@media (min-width: 900px) {
  .buyTicketContainer {
    display: flex;
    flex-direction: row;
    background-color: #f0f0f0;
    justify-content: center;
    max-width: 1380px;
    align-items: flex-start;
  }

  .buyTicketLeftSection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buyTicketRightSection {
    display: flex;
    flex-direction: column;
  }
  .buyTicketRightTop {
    display: initial;
  }

  .buyTicketRightBottom {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-left: 25px;
  }
  .disactive {
    display: initial;
    width: 315px;
  }
}

.grayed {
  opacity: 0.4;
  pointer-events: none;
}

.navigationButtonsBox {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 25px;
  position: relative;
}

.nextBackButtons {
  width: 100%;
  max-width: 350px;
  padding:0 8px;
  display: flex;
  justify-content: space-between;
}
