
.bigTicketPriceMobile {
    display: initial;
    position: absolute;
    top: -36px;
    right: 20px;
}
.bigTicketPriceWeb {
    display: none;
}
.addPersonText {
    display: flex;
    font-size: 14px;
    font-family: Helvetica Neue LT W05_55 Roman,sans-serif;
    opacity: 0.5;
    text-decoration: underline;
    cursor: pointer;
}
.checkoutTicketPrice{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 130px;
}

.closeButton{
    width: 16px;
}
.pointer{
    cursor: pointer;
}
.selectRightContainer{
    width: 100%;
    display: flex;
    flexD-drection: row;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 900px) {

    .bigTicketPriceWeb {
        display: initial;
        margin-bottom: 4px;
    }
    .bigTicketPriceMobile {
        display: none;
    }
    .select{
        width:300px;
    }
    .ticketPrice{
        display: flex;
        flex-direction: column;
    }
    .selectRightContainer{
        width: 50%;
    }
}
