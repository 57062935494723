.carousel-arrow-back{
    position: absolute;
    left:0;
    display:flex;
    height:200px;
    opacity: 0.7;
    background-color: white ;
    padding:10px;
    align-items: center;
    top:0;
    z-index: 10;
}

.carousel-arrow-next{
    position: absolute;
    right:0;
    display:flex;
    height:200px;
    padding:10px;
    opacity: 0.7;
    background-color: white ;
    align-items: center;
    top:0;
    z-index: 10;
}
.clean-navigation-button{
    border:none;
    background-color: transparent;
    padding: 0;
    margin: 0;
}
