.subtotalContainer {
  width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 25px;
  position: relative;
  padding-top: 8px;
}

.totalCostsH3 {
  font-size: 16px;
  text-transform: uppercase;
  color: #2b2b2c;
  display: flex;
}

.totalCostsText {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 2px solid black;
}
.totalCurrency {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 4px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
}
.totalPrice {
  font-size: 20px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  line-height: 1;
}
.sumTickets {
  color:#000000;
  font-weight: 900;
  font-size: 0.7em;
  line-height: 1.2;
}
.customerTickets {
  width: 100%;
  text-align: left;
}
.vatIncluded {
  display: block;
  width: 100%;
  color: #787878;
  text-align: center;
  margin-top: 8px;
}

.grayedSubTotalBox {
  opacity: 0.4;
  text-align: center;
  pointer-events: none;
  width:100px;
}

.subTotalBox {
text-align: center;
  width:100px;
}

.loginButtonContainer {
 display:none;
}
@media (min-width: 900px) {
  .subTotalBox {
    text-align: center;
    position:static;
  }
  .payButtonContainer {
    position: initial;
  }
  .loginButtonContainer {
    display: initial;
    background-color: red;
  }
}
