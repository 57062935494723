.wrapper-widget-container{
    position: relative;
    width: 100%;
}
.widget-container {
    position: absolute;
    bottom:0;
    width: calc(100% - 40px);
    height: 140px;
    padding: 0px 20px;
    color: #ffffff;
    transition: height 0.5s;
    overflow: hidden;
}
.product-name {
    width: 100%;
    padding: 20px 0 10px;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 1;
 }
.inputs {
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    transition: opacity 0.2s;
    transition-timing-function: ease-in;
    padding-bottom: 10px;
}
.product-details {
    width: calc(100% -  40px);
    position: absolute;
    bottom: 0px;
    right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}
.submit-button-white{
    border: none;
    cursor: pointer;
    position:relative;
    z-index: 1;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
    font-size: 22px;
    padding: 10px 20px;
}

.select-date-modal {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;

}

.input-date {
    background-color: rgb(240, 240, 240);
    display: flex;
    color: black;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

/*Mobile css*/

.widget-container-mobile {
    position: absolute;
    bottom:0;
    width: calc(100% - 20px);
    height: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 10px;
    color: #ffffff;
    transition: height 0.5s;
    align-items: center;
    overflow: hidden;
}

.product-name-mobile {
    width: 50%;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
    font-weight: 400;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.inputs-mobile {
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    transition: opacity 0.5s;
    padding-bottom: 55px;
    align-items: center;
}

.product-details-mobile {
    position: absolute;
    bottom: 0px;
    left: 10px;
    padding-bottom: 5px;
    transition: opacity 0.5s,height .5s;
}
.submit-button-white-mobile{
    border: none;
    cursor: pointer;
    position:relative;
    z-index: 1;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    transition: opacity 0.2s;
}
