
.rowContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  line-height: 1.6;
  padding: 12px 0 0;
  position: relative;
}

.leftSectionText {
  display: flex;
  flex-direction: column;
  color: #787878;
}
.keyCardNumber {
  color: #787878;
}
.dateInBookedTicket {
  color: #2b2b2c;
  font-size: 16px;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  width: 200px;
  max-width: 200px;
  overflow-x: auto;
}

.rightSectionIcon {
  cursor: pointer;
  align-self: flex-start;
  width: 50px;
  max-width: 50px;
  min-width: 50px;
}
.rightSectionIcon > img {
  width: 18px;
}

.userProfileLine {
  display: block;
  width: 290px;
  padding-top: 12px;
  border-bottom: solid 2px #f0f0f0;
  color: #787878;
}

.closeButtonUserProfile {
  margin-left: 10px;
  width: 15px !important;
}
