.signIn-container {
    width: 315px;
    min-height: 168px;
    background: #ffffff;
    padding: 6px 25px;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.signIn-button-container {
    width: 100%;
    padding-bottom: 4px;
}
