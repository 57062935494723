.categories-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:100%;
    margin: 5px 0;
}

.category-container{
    min-width:48%;
    text-align: center;
    margin-top:10px;
    padding:16px 0;
    background-color: white;
    cursor: pointer;
}
