.userPanelContainer {
  display: flex;
  justify-content: space-between;
  width: 315px;
  box-sizing: border-box;
  margin: 0 25px;
  flex-direction: column;
}
.userPanelMenu {
  background-color: #fff;
}

.avatar {
  width: 222px;
  height: 222px;
  background-color: cadetblue;
  margin: 36px;
  box-sizing: border-box;
}

.userPanelRightSection {
  display: flex;
  flex-direction: column;
  width: 315px;
  margin-top: 25px;
  margin-left: 0px;
}

.userPanelTopRight {
  background-color: #fff;
  width: 994px;
  height: 225px;
  margin-bottom: 25px;
  display: none;
}

.userPanelBottomRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userPanelBox {
  width: 315px;
  height: 468px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.userProfileLine {
  display: block;
  width: 290px;
  border-bottom: solid 2px #f0f0f0;
  color: #787878;
}

@media (min-width: 900px) {
  .userPanelContainer {
    display: flex;
    justify-content: space-between;
    width: 1380px;
    box-sizing: border-box;
    padding: 0 25px;
    flex-direction: row;
  }
  .userPanelMenu {
    background-color: #fff;
  }

  .avatar {
    width: 222px;
    height: 222px;
    background-color: cadetblue;
    margin: 36px;
    box-sizing: border-box;
  }

  .userPanelRightSection {
    display: flex;
    flex-direction: column;
    width: 994px;
    margin-top: 0px;
    margin-left: 25px;
  }

  .userPanelTopRight {
    background-color: #fff;
    width: 994px;
    height: 225px;
    margin-bottom: 25px;
    display: initial;
  }

  .userPanelBottomRight {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .userPanelBox {
    width: 315px;
    height: 468px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
    margin-bottom: 0;
  }

  .userProfileLine {
    display: block;
    width: 290px;
    border-bottom: solid 2px #f0f0f0;
    color: #787878;
  }
}
