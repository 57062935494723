.cookies-panel {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #2b2b2c;
  padding: 1em;
  z-index: 1;
}

.more-info {
  text-decoration: underline;
}