.pay-button {
  width: 124px;
  height: 43px;
  background-color: #2b2b2c;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.pay-button-disabled {
  color: #fff;
  background-color: #c3c3c3;
  cursor: default;
}