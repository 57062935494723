.qrCodeDetectorContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;
    width: 300px;
}
.ratioCircleQrCode{
    width: 10px;
    height:10px;
    border: 2px solid rgb(240, 240, 240);
    border-radius: 10px;
    margin-right:10px;
}
.ratioDotQrCode{
    margin: 2.5px;
    background-color:#af272f;
    width: 3px;
    height:3px;
    border: 1px solid #af272f;
    border-radius: 3px;
}
