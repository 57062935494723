.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.container-menu{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 220px;
  box-sizing: border-box;
}
.shortMenuLine {
  width: 26px;
  height: 2px;
  background-color: #787878;
  margin: 6px 0;
}
.longMenuLine {
  width: 36px;
  height: 2px;
  background-color: #787878;
  margin-bottom: 6px;
}

.menuTitle {
  text-transform: uppercase;
  font-size: 12px;
  color: #787878;
}
.logOut {
  text-transform: uppercase;
  font-size: 14px;
  color: #787878;
  padding-left: 20px;
  cursor: pointer;
  text-decoration: underline;
}
.menuLogoContainer {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 0;
  top: 70px;
  z-index: 2;
  display: initial;
}

@media (min-width: 900px) {
  .menuLogoContainer {
    display: none;
  }
  .container-menu{
    height: 140px;
  }
}

