.payment-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.payment-close {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: unset;
  border: unset;
  cursor: pointer;
}

.payment-close > img {
  width: 40px;
  height: 40px;
}

.payment-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.payment-iframe {
  z-index: 200;
  background-color: white;
  border: 0;
}

@media (max-width: 899px) {
  .payment-iframe {
    width: 300%;
    height: 165%;
    transform: scale(0.6);
  }
}

@media (min-width: 900px) {
  .payment-iframe {
    width: 90%;
    height: 90%;
  }
}