.upgrades-container {
    width: 315px;
    min-height: 168px;
    background: #ffffff;
    padding: 6px 25px 0;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.upgrades-circle {
    position: absolute;
    width: 40px;
    height: 20px;
    border: 1px solid black;
    border-radius: 15px;
    right: 0;
    cursor: pointer;
}
.upgrades-dot{
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid transparent;
    border-radius: 9px;
    transition: all  0.3s ;
}
.detailsContainer {
    background-color: #fff;
    position: relative;
    padding: 15px;
    width: 300px;
    color: black;
}

.detailsContainer img{
    max-width: 300px;
}

.detailsContainer strong {
    color: black;
}
.exitButtonDetails{
    position: absolute;
    right: 15px;
    top: 0;
}
