.container {
    width: 100%;
    font-size: 12px;
    padding-bottom: 6px;
}
.fieldLabel {
    font-size: 14px;
    color: #787878;
}

.child {
    width: 100%;
    position: relative;
}
