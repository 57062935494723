.ticketMoreDetailsContainer {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-items: stretch;
  justify-content: stretch;
}

.moreDetailsExit {
  position: absolute;
  right: 0;
  top: 0;
}

.moreDetailsButtons{
  width: 100%;
  margin-bottom: 6px;
}

.ticketButtonContainer{
  display: flex;
  justify-content: flex-start;
  text-align: start;
  align-items: center;
}


