@import url('https://fast.fonts.net/cssapi/89b15ae3-041a-4056-a712-2f1fe1dd5431.css');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* line-height: 1.86; */
  font-family: Helvetica Neue LT W05_55 Roman,sans-serif;
  letter-spacing: 0.03em;
  font-size: 18px;
  background-color: transparent;
}
span {
  font-size: 14px;
}
.wrapper-main-container {
  background-color: #f0f0f0;
}
.main-container {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

@media (min-width: 1380px) {
  .main-container {
    transform: scale(0.95);
    transform-origin: top;
  }
}

@media (min-width: 1251px) and (max-width: 1379px) {
  .main-container {
    transform: scale(0.8);
    transform-origin: top;
  }
}

@media (min-width: 1145px) and (max-width: 1250px) {
  .main-container {
    transform: scale(0.7);
    transform-origin: top;
  }
}

@media (min-width: 1000px) and (max-width: 1144px) {
  .main-container {
    transform: scale(0.7);
    transform-origin: top;
  }
}

@media (min-width: 900px) and (max-width: 999px) {
  .main-container {
    transform: scale(0.65);
    transform-origin: top;
  }
}

code {
  font-family: Roboto, sans-serif;
}

h1 {
  color: #b30019;
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: 1.5;
}
h1,h2,
h3 {
  /*font-family: Helvetica Now Text, ExtraBold, sans-serif;*/
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
  font-weight: 400;
  letter-spacing: .005em;
}

.font-grey {
  color: #787878;
}

.element-on-hover {
  cursor: pointer;
}

.small-grey-text {
  font-size: 0.7em;
  line-height: 90%;
}

.p-main {
  color: #787878;
}

.react-datepicker__day--selected {
  background-color: #b30019 !important;
}
.react-datepicker__navigation--next {
  background-color: #ccc !important;
  mask: url(./icons/arrow-right-black.svg) no-repeat center !important;
  margin: 5px !important;
  width: 15px !important;
  height: 15px !important;
  border: none !important;
}
.react-datepicker__navigation--previous {
  background-color: #ccc !important;
  mask: url(./icons/arrow-left-red.svg) no-repeat center !important;
  margin: 5px !important;
  width: 15px !important;
  height: 15px !important;
  border: none !important;
}
.focusRing___1airF.carousel__slide-focus-ring { outline: none !important; }
.transparent-btn {
  background: transparent;
  border: none !important;
}

.availability-dot {
  width: 7px;
  height: 7px;
  display: block;
  border-radius: 50%;
  margin-top: 5px;
}


@media (min-width: 900px) {
  body {
    margin: 0 auto;
  }
  h1 {
    font-size: 2.5em;
    line-height: 1;
  }
}
