.main-option-button {
  width: 36px;
  height: 36px;
  text-transform: uppercase;
  border: none;
  margin: 0;
  padding: 2px;
  font-weight: bold;
  cursor: pointer;
  font-family: Helvetica Neue LT W05_85 Heavy,sans-serif;
}
